import { swapObjectKeyValues } from '../utils/objectUtil';

export const CF_LOCALES: Record<string, string> = {
  de: 'de-LU',
  en: 'en-BE',
  fr: 'fr-BE',
  nl: 'nl-BE',
} as const;

export const REVERSED_CF_LOCALES = swapObjectKeyValues(CF_LOCALES);

export const BLOCK_IMAGE_SIZES = {
  MEDIUM: 'medium',
  SMALL: 'small',
  WIDE: 'wide',
} as const;
export type BlockImageSize = (typeof BLOCK_IMAGE_SIZES)[keyof typeof BLOCK_IMAGE_SIZES];

export const APP_IDENTIFIER = '__app_link';
